const COMPONENT_QUESTION = 'QuestionsListContainer';
const COMPONENT_REDACTION_INSTRUCTION = 'ContainerInstructionProposal';
const COMPONENT_SEND_REDACTION = 'ContainerSendRedaction';

const NEXT_PREVIOUS_TAB_NUMBER = 1;

const FORMAT_SHOWING_QUESTIONS = 'all';

const COMPONENT_MODAL_WARNING_REDACTION = 'modalWarningRedaction';
const COMPONENT_MODAL_WARNING_QUESTION = 'modalWarningQuestion';
const COMPONENT_MODAL_SUCCESS = 'ModalSuccessSimulated';

const TAB_REDACTION = {
  slug: '',
  id: 'redacao',
  title: 'Redação',
  active: false
};

const LIST_TAB = [
  { title: 'Linguagens', id: 'lc_lt', active: false },
  { title: 'Humanas', id: 'ch_ct', active: false },
  { title: 'Natureza', id: 'cn_ct', active: false },
  { title: 'Matemática', id: 'mt', active: false }
];

const TAB_NAME_LANGUAGES = 'lc_lt';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";

const MSG_ERROR_LIST = 'Erro ao carregar lista. Tente novamente em instantes.';

export const LEVELING_CONSTANT = {
  COMPONENT_QUESTION,
  COMPONENT_REDACTION_INSTRUCTION,
  COMPONENT_SEND_REDACTION,
  FORMAT_SHOWING_QUESTIONS,
  COMPONENT_MODAL_WARNING_REDACTION,
  COMPONENT_MODAL_WARNING_QUESTION,
  COMPONENT_MODAL_SUCCESS,
  TAB_REDACTION,
  TAB_NAME_LANGUAGES,
  DATE_FORMAT,
  LIST_TAB,
  MSG_ERROR_LIST,
  NEXT_PREVIOUS_TAB_NUMBER
};

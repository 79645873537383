






























































import { Component, Vue, Prop } from 'vue-property-decorator';

import ButtonMovie from '@/components/ButtonMovie/ButtonMovie.vue';
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon.vue';

@Component({
  components: {
    ButtonMovie,
    ButtonIcon
  }
})
export default class Buttons extends Vue {
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: false }) disabledButtonFinished!: boolean;

  private showMenu = false;

  get isMobile() {
    return this.$responsive.isMobile;
  }

  handleFinish() {
    this.$emit('finished');
  }

  handleShowMenu() {
    this.showMenu = !this.showMenu;
  }

  handleHideMenu() {
    this.showMenu = false;
  }
}















































import {
  Component, Watch, Mixins, Prop
} from 'vue-property-decorator';
import { format, differenceInSeconds } from 'date-fns';

import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import Loading from '@/components/Loading/Loading.vue';
import ContainerInstructionProposal from '@/components/Redaction/ContainerInstructionProposal/ContainerInstructionProposal.vue';
import ContainerSendRedaction from '@/components/Redaction/ContainerSendRedaction/ContainerSendRedaction.vue';
import ButtonMovie from '@/components/ButtonMovie/ButtonMovie.vue';
import QuestionsListContainer from '@/components/QuestionsListContainer/QuestionsListContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import QuestionLoading from '@/components/Exercise/Question/QuestionLoading.vue';

import ModalWarningQuestion from '../Modals/Question/ModalWarningQuestion.vue';
import ModalWarningRedaction from '../Modals/Redaction/ModalWarningRedaction.vue';
import Buttons from './components/Buttons/Buttons.vue';

import ActiveModal from '@/share/Util/ActiveModal';
import { UrlTreatmentSaveVersa } from '@/share/Util/Image/Image';
import ScrollControl from '@/share/Util/ScrollControl/ScrollControl';

import WarService from '@/services/War/WarService';
import ListService from '@/services/List/ListService';
import RedactionService from '@/services/Redaction/RedactionService';

import ListQuestions from '@/mixins/ListQuestions';

import { List, SaveList } from '@/globalInterfaces/List';
import { ConfigWar } from '@/globalInterfaces/War';
import {
  ReceivedRedaction as IReceivedRedaction,
  Redaction as IRedaction
} from '@/pages/Simulated/interface/ISimulated';

import { STEPS_WAR } from '@/constant/War';
import { LEVELING_CONSTANT } from './constants';
import { NAME_ROUTES_CONQUISTAE } from '@/router/routes/War';

@Component({
  components: {
    Tabs,
    Loading,
    ContainerInstructionProposal,
    ContainerSendRedaction,
    QuestionsListContainer,
    ButtonMovie,
    FeedbackUser,
    QuestionLoading,
    Buttons,
    ModalWarningRedaction,
    ModalWarningQuestion
  }
})
export default class Leveling extends Mixins(ListQuestions) {
  @Prop() start!: boolean;

  private isLoadingList = false;
  private tabActiveIsRedaction = false;
  private isOpenComponentsInputRedaction = false;
  private isLoadingButtonSend = false;
  private showedRedactionModal = false;

  private dateTimeStartList = '';
  private feedbackMessage = LEVELING_CONSTANT.MSG_ERROR_LIST;

  private selectComponent = LEVELING_CONSTANT.COMPONENT_QUESTION;
  private tabActive = '';

  private tabMenu: Array<TabsMenuInterface> = [];

  private redaction: IRedaction = {
    themeID: 0,
    content: ''
  };

  private list: List | null = null;

  private setModal = new ActiveModal();
  private setScroll = new ScrollControl();

  private WarService = new WarService();
  private ListService = new ListService();
  private RedactionService = new RedactionService();

  created() {
    this.resetStatusSentSimulatedInStore();

    this.getListQuestions();
  }

  mounted() {
    window.addEventListener('beforeunload', this.listenReloadPage);
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.listenReloadPage);
  }

  get isShowAllQuestion() {
    const { formatShowingQuestions } = this.$store.getters;

    return formatShowingQuestions === LEVELING_CONSTANT.FORMAT_SHOWING_QUESTIONS;
  }

  get themeIdRedaction() {
    return this.list?.theme.ID;
  }

  get dateFormatted() {
    return `${format(new Date(), LEVELING_CONSTANT.DATE_FORMAT)}Z`;
  }

  get configWar(): ConfigWar | null {
    return this.$store.getters['War/configWar'];
  }

  @Watch('tabActiveIsRedaction')
  changeActiveTabComponent() {
    if (this.tabActiveIsRedaction) {
      this.selectComponent = this.isOpenComponentsInputRedaction
        ? LEVELING_CONSTANT.COMPONENT_SEND_REDACTION
        : LEVELING_CONSTANT.COMPONENT_REDACTION_INSTRUCTION;
    } else this.selectComponent = LEVELING_CONSTANT.COMPONENT_QUESTION;
  }

  @Watch('isShowAllQuestion')
  @Watch('tabActiveIsRedaction')
  setScrollPage() {
    if (this.isShowAllQuestion || this.tabActiveIsRedaction) this.setScroll.scrollTopPage();
  }

  @Watch('start')
  startTimer() {
    this.dateTimeStartList = this.dateFormatted;
  }

  async getListQuestions() {
    try {
      this.isLoadingList = true;

      const list = await this.WarService.getListLeveling();

      if (!list) return;

      this.list = list;

      const response = await this.ListService.getListQuestions({
        listQuestionsID: [list.ID],
        isOptions: true
      });

      if (response) {
        this.separateQuestionsByArea(this.groupQuestionsByAreas(response));

        this.setTabMenu();
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: LEVELING_CONSTANT.MSG_ERROR_LIST,
        status: 'error'
      });
    } finally {
      this.isLoadingList = false;
    }
  }

  async saveLeveling() {
    try {
      this.isLoadingButtonSend = true;

      if (!this.list) return;

      const answers = this.modalSaveAnswerQuestions();

      if (!answers) return;

      let redactionID: number | null = null;
      const startDate = this.dateTimeStartList;
      const endDate = `${format(new Date(), LEVELING_CONSTANT.DATE_FORMAT)}Z`;
      const time = differenceInSeconds(new Date(endDate), new Date(this.dateTimeStartList));

      if (this.redaction.themeID && this.configWar?.isRedaction) {
        const type = this.redaction.content ? 'content' : 'URL';

        const response = await this.RedactionService.saveRedaction({
          themeID: this.redaction.themeID,
          [type]: this.redaction.content || this.redaction.URL,
          time: null
        });

        redactionID = response.data.id;
      }

      const saveList: SaveList = {
        listQuestionsID: this.list.ID,
        startDate,
        endDate,
        time,
        redactionID,
        answers
      };

      await this.ListService.saveList(saveList);

      this.$store.dispatch('Toast/setToast', {
        text: 'Nivelamento salvo com sucesso!',
        status: 'success'
      });

      this.$store.commit('War/setConfig', {
        ...this.configWar,
        step: {
          ...this.configWar?.step,
          ID: STEPS_WAR.RESULT_QUESTIONS
        }
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.redirectToLevelingResult();

    } catch (error) {
      this.$store.commit('setSimulatedSent', true);

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar a lista.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingButtonSend = false;
    }
  }

  redirectToLevelingResult() {
    this.$router.push({ name: NAME_ROUTES_CONQUISTAE.LEVELING_RESULT });
  }

  resetStatusSentSimulatedInStore() {
    this.$store.commit('setSimulatedSent', false);
  }

  listenReloadPage(e: BeforeUnloadEvent) {
    e.preventDefault();

    e.returnValue = '';

    return '';
  }

  setTabMenu() {
    let newFilteredTab: TabsMenuInterface[] = LEVELING_CONSTANT.LIST_TAB.filter(
      (tab: TabsMenuInterface) => (tab.id ? this.newQuestions[tab.id].length : '')
    );

    if (this.list?.theme?.ID && this.configWar?.isRedaction) {
      newFilteredTab = [
        ...newFilteredTab,
        {
          title: LEVELING_CONSTANT.TAB_REDACTION.title,
          id: LEVELING_CONSTANT.TAB_REDACTION.id,
          active: false
        }
      ];
    }

    newFilteredTab[0] = { ...newFilteredTab[0], active: true };

    this.tabMenu = newFilteredTab;

    this.setTabActive();
  }

  setTabActive() {
    this.tabActive = this.tabMenu[0].id || '';
  }

  clickButtonFinishModalQuestion() {
    if (this.list?.theme?.ID && this.configWar?.isRedaction) {
      this.showedRedactionModal = true;
    }

    this.saveLeveling();

    this.disableModal('modalWarningQuestion');
  }

  clickLeftButtonModalRedaction() {
    if (this.list?.theme?.ID && this.configWar?.isRedaction) {
      this.showedRedactionModal = true;
    }

    this.verifyRedactionAndQuestionsBeforeSendList();
  }

  clickRightButtonModalRedaction() {
    const indexTabRedaction = this.tabMenu.findIndex(
      (tab: TabsMenuInterface) => tab.id === LEVELING_CONSTANT.TAB_REDACTION.id
    );

    this.setCurrentlyActiveTab(indexTabRedaction);

    this.handleActiveTabSwitching(indexTabRedaction, this.tabMenu);
  }

  showModal(idModal: string) {
    this.setModal.activeModal(idModal);
  }

  verifyRedactionAndQuestionsBeforeSendList() {
    let isApprovedToSend;

    if (!this.showedRedactionModal) isApprovedToSend = this.verifyRedactionContent() && this.verifyAnsweredQuestions();
    else {
      this.showedRedactionModal = false;

      isApprovedToSend = this.verifyAnsweredQuestions();
    }

    if (isApprovedToSend) this.saveLeveling();
  }

  verifyRedactionContent() {
    if (this.themeIdRedaction && this.configWar?.isRedaction) {
      const hasRedaction = !!this.redaction.content || !!this.redaction.URL;

      if (!hasRedaction) this.showModal(LEVELING_CONSTANT.COMPONENT_MODAL_WARNING_REDACTION);

      return hasRedaction;
    }

    return true;
  }

  verifyAnsweredQuestions() {
    const isAnsweredQuestions = this.answerQuestions.length === this.totalQuantityQuestions;

    if (!isAnsweredQuestions) this.showModal(LEVELING_CONSTANT.COMPONENT_MODAL_WARNING_QUESTION);

    return isAnsweredQuestions;
  }

  getInputRedaction(receivedRedaction: IReceivedRedaction) {
    if (!this.themeIdRedaction) return;

    this.redaction = {
      themeID: this.themeIdRedaction,
      [receivedRedaction.type]: UrlTreatmentSaveVersa(receivedRedaction.content)
    };
  }

  setCurrentlyActiveTab(indexTab: number) {
    this.tabMenu = this.tabMenu.map((tab: TabsMenuInterface, index: number) => {
      if (index === indexTab) return { ...tab, active: true };

      return { ...tab, active: false };
    });
  }

  setNextTab() {
    const indexNextTab = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === this.tabActive)
      + LEVELING_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexNextTab);

    if (indexNextTab !== -1) this.handleActiveTabSwitching(indexNextTab, this.tabMenu);
  }

  setPreviousTab() {
    const indexPreviousTab = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === this.tabActive)
      - LEVELING_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexPreviousTab);

    if (indexPreviousTab !== -1) this.handleActiveTabSwitching(indexPreviousTab, this.tabMenu);
  }

  handleActiveTabSwitching(indexTabSelected: number, tabData: Array<TabsMenuInterface>) {
    this.tabMenu = tabData;

    this.tabActiveIsRedaction = tabData[indexTabSelected].id === LEVELING_CONSTANT.TAB_REDACTION.id;

    this.tabActive = tabData[indexTabSelected].id || '';
  }

  toGoBackInstructionRedaction() {
    this.selectComponent = LEVELING_CONSTANT.COMPONENT_REDACTION_INSTRUCTION;

    this.isOpenComponentsInputRedaction = false;
  }

  openComponentInputRedaction() {
    this.selectComponent = LEVELING_CONSTANT.COMPONENT_SEND_REDACTION;

    this.isOpenComponentsInputRedaction = true;
  }

  disableModal(idModal: string) {
    if (!idModal) return;

    this.setModal.disableModal(idModal);
  }
}

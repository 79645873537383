





























import { Component, Vue } from 'vue-property-decorator';

import Character from '../../../../components/Character/Character.vue';

@Component({
  components: {
    Character
  }
})
export default class Message extends Vue {
  get isMobile() {
    return this.$responsive.isMobile;
  }

  handleStartQuestion() {
    this.$emit('start');
  }
}

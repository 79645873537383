






























import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import Character from '../../Character/Character.vue';

@Component({
  components: {
    Modal,
    Character
  }
})
export default class ModalWarningQuestion extends Vue {
  handleClickButtonFinish() {
    this.$emit('click-button-finish');
  }
}
